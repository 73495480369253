import crpto from '../assets/projects/crpto.png'
import canberra from '../assets/canberra.png'

export const data=[
    {
        id:1,
        name:"crpto tracking",
        image:crpto,
        github:"https://github.com/ucfangyiwang/cryptotracking",
        live:"https://ucfangyiwang.github.io/cryptotracking/",
    },
    {
        id:2,
        name:"canberra model",
        image:canberra,
        github:"https://github.com/ucfangyiwang/project",
        live:"https://ucfangyiwang.github.io/project/",
    },
    
    

]